export const ROOT_URI = `https://web.mybiocalculus.com`;
export const API_ENDPOINT = `https://web.mybiocalculus.com:3002/api`;

export const FIRST_PAGE_IMAGE_PRIORITY = [
  { key: "sp", name: "Sinus Pause" },
  { key: "af", name: "Atrial Fibrillation" },
  { key: "vt", name: "Ventricular Tachycardia" },
  { key: "chb", name: "Complete heart block" },
  { key: "2avb", name: "2nd  degree AV block" },
  { key: "flutter", name: "Atrial Flutter" },
  { key: "j", name: "Junctional beat" },
  { key: "pvc", name: "Premature Ventricular Ectopics" },
  { key: "pac", name: "Premature Atrial Ectopics" },

  { key: "st", name: "Sinus Tachycardia" },
  { key: "sb", name: "Sinus Bradycardia " },
  { key: "nsr", name: "Sinus Rhythm" },
  { key: "max", name: "Maximum HR" },
  { key: "min", name: "Minimum HR" },
];

export const ARRYTHMIA_FOLDERS = [
  {
    key: "max",
    name: "Maximum HR",
  },
  {
    key: "min",
    name: "Minimum HR",
  },
  {
    key: "sym",
    name: "Triggerred Event",
  },
  {
    key: "chb",
    name: "Complete heart block",
  },
  {
    key: "2avb_type1",
    name: "Type 1 2nd degree  AV block",
  },
  {
    key: "2avb_type2",
    name: "Type 2 2nd  degree AV block",
  },
  {
    key: "2avb2:1",
    name: "2:1 2nd degree  AV block",
  },
  {
    key: "2avb3:1",
    name: "3:1 2nd  degree AV block",
  },
  {
    key: "2avb",
    name: "2nd  degree AV block",
  },
  {
    key: "bbb",
    name: "Bundle Branch Block",
  },
  {
    key: "1avb",
    name: "First degree AV block",
  },
  {
    key: "vt",
    name: "Ventricular Tachycardia",
  },
  {
    key: "ays",
    name: "Asystole",
  },
  {
    key: "at",
    name: "Atrial Tachycardia",
  },
  {
    key: "sp",
    name: "Sinus Pause",
  },
  {
    key: "af",
    name: "Atrial Fibrillation",
  },
  {
    key: "flutter",
    name: "Atrial flutter",
  },
  {
    key: "arrest",
    name: "sinus arrest",
  },
  {
    key: "block",
    name: "Sinus Block",
  },
  {
    key: "svt",
    name: "Supra Ventricular Tachycardia",
  },
  {
    key: "aivr",
    name: "Accelerated IdioVentricular Rhythm(AIVR)",
  },
  {
    key: " ivr",
    name: "IdioVentricular  Rhytm(IVR)",
  },
  {
    key: "pjc",
    name: "Premature Junctional Complex",
  },
  {
    key: "run_pvc",
    name: "PVC Run",
  },
  {
    key: "triplet_pvc",
    name: "PVC Triplet",
  },
  {
    key: "couplet_pvc",
    name: "PVC Couplet ",
  },
  {
    key: "bi_pvc",
    name: "PVC Bigeminy",
  },
  {
    key: "tri_pvc",
    name: "PVC Trigeminy",
  },
  {
    key: "pvc",
    name: "PVC",
  },
  {
    key: "run_pac",
    name: "PAC Run",
  },
  {
    key: "triplet_pac",
    name: "PAC Triplet",
  },
  {
    key: "couplet_pac",
    name: "PAC Couplet",
  },
  {
    key: "tri_pac",
    name: "PAC Trigeminy",
  },
  {
    key: "bi_pac",
    name: "PAC Bigeminy",
  },
  {
    key: "pac",
    name: "PAC",
  },
  {
    key: "sa",
    name: "Sinus Arrhythmia",
  },
  {
    key: "st",
    name: "Sinus Tachycardia",
  },
  {
    key: "sb",
    name: "Sinus Bradycardia",
  },
  {
    key: "j",
    name: "Junctional Beats",
  },

  {
    key: "ncar",
    name: "Non concducted Pwave",
  },
  {
    key: "invt",
    name: "Inverted Twave",
  },
  {
    key: "nsr",
    name: "Normal Sinus Rhythm",
  },
];
