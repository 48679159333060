import { useEffect, useState } from "react";
import UncontrolledTextArea from "../UncontrolledTextArea/UncontrolledTextArea";
import "./Table.scss";
import './table.css'

const hoursToShade = [22, 23, 24, 0, 1, 2, 3, 4, 5, 6];

const Table = ({ data: rawData }) => {
  const [data, setData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null); // Track the selected row for deletion
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    let allItems = JSON.parse(rawData.replace(/'/gi, '"'));
    const heading = [...allItems][0][0];
    allItems.splice(0, 1);
    setData({ data: allItems, heading });
  }, [rawData]);

  if (!data) {
    return null; // Return null instead of an empty string
  }

  const handleHeadingChange = (newHeading) => {
    setData((prevData) => ({ ...prevData, heading: newHeading }));
  };

  const handleRowClick = (index, e) => {
    e.stopPropagation(); // Prevent event bubbling
    const tableRect = e.currentTarget.parentNode.getBoundingClientRect(); // Get table dimensions
    const clickX = e.clientX;
    const clickY = e.clientY;

    let popupX = clickX - tableRect.left; // Position relative to the table
    let popupY = clickY - tableRect.top;

    // Adjust popup to stay within the table bounds
    const maxPopupX = tableRect.width - 100; // Assuming popup is 100px wide
    const maxPopupY = tableRect.height - 50; // Assuming popup is 50px tall

    popupX = Math.min(popupX, maxPopupX); // Prevent overflow on X-axis
    popupY = Math.min(popupY, maxPopupY); // Prevent overflow on Y-axis

    setSelectedRow(index === selectedRow ? null : index); // Toggle row selection
    setPopupPosition({ top: popupY, left: popupX }); // Position popup inside the table
    setPopupVisible(index !== selectedRow); // Show popup if a row is selected
  };

  const handleDeleteRow = (index) => {
    const updatedData = data.data.filter((_, i) => i !== index);
    setData({ ...data, data: updatedData });
    setSelectedRow(null); // Reset selection after deletion
    setPopupVisible(false); // Hide popup after deletion
  };

  const handleAddRow = () => {
    const newRow = Array(data.data[0].length).fill(""); // Create a new row with empty cells
    const updatedData = [...data.data, newRow];
    setData({ ...data, data: updatedData });
    setPopupVisible(false); // Hide the popup after adding a row
  };

  return (
    <div className="table">
      <input
        className="table__header"
        type="text"
        value={data.heading}
        onChange={(e) => handleHeadingChange(e.target.value)}
        style={{
          border: "none",
          textAlign: "center",
          position: "relative",
          left: "50%",
          width: "190px",
          backgroundColor: "transparent",
          transform: "translate(-50%, -50%)",
          outline: "none",
          fontSize: "9px",
        }}
      />

      <table className="table__table" style={{ marginTop: "-8px" }}>
        <thead>
          <tr>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Hour" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="Beats" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Max HR" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Min HR" />
            </th>
            <th style={{ width: "20px" }}>
              <UncontrolledTextArea defaultValue="Avg HR" />
            </th>
            <th style={{ width: "22px" }}>
              <UncontrolledTextArea defaultValue="Tachy (SR > 100)" />
            </th>
            <th style={{ width: "22px" }}>
              <UncontrolledTextArea defaultValue="Brady (SR < 60)" />
            </th>
            <th style={{ width: "15px" }}>
              <UncontrolledTextArea defaultValue="SP > 2 sec" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="V ectopy" />
            </th>
            <th style={{ width: "16px" }}>
              <UncontrolledTextArea defaultValue="V Pair" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="V Triplet" />
            </th>
            <th style={{ width: "30px" }}>
              <UncontrolledTextArea defaultValue="V Bigemni" />
            </th>
            <th style={{ width: "34px" }}>
              <UncontrolledTextArea defaultValue="V Trigemny" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="A Ectopy" />
            </th>
            <th style={{ width: "18px" }}>
              <UncontrolledTextArea defaultValue="A Pair" />
            </th>
            <th style={{ width: "24px" }}>
              <UncontrolledTextArea defaultValue="A Triplet" />
            </th>
            <th style={{ width: "33px" }}>
              <UncontrolledTextArea defaultValue="A Bigemny" />
            </th>
            <th style={{ width: "34px" }}>
              <UncontrolledTextArea defaultValue="A Trigemny" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="VT" />
            </th>
            <th style={{ width: "25px" }}>
              <UncontrolledTextArea defaultValue="SVT" />
            </th>
            <th>
              <UncontrolledTextArea defaultValue="AFib" />
            </th>
            <th style={{ width: "30px" }}>
              <UncontrolledTextArea defaultValue="Atrial Run" />
            </th>
            <th style={{ width: "38px" }}>
              <UncontrolledTextArea defaultValue="Ventricular Run" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((row, index) => (
            <tr
              key={data.heading + index}
              className={hoursToShade.includes(row[0]) ? "table__row-shade" : ""}
              onClick={(e) => handleRowClick(index, e)} // Pass event to handleRowClick
            >
              {row.map((dt, i) => (
                <td key={data.heading + index + i}>
                  <input defaultValue={dt} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Popup for Add and Delete */}
      {popupVisible && selectedRow !== null && (
        <div
          className="table__row-popup"
          style={{
            top: popupPosition.top, // Use the updated top position
            left: popupPosition.left, // Use the updated left position
          }}
        >
          <button onClick={handleAddRow}>Add Row</button>
          <button onClick={() => handleDeleteRow(selectedRow)}>Delete Row</button>
        </div>
      )}
    </div>
  );
};

export default Table;
